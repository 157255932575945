/* eslint-disable react-hooks/rules-of-hooks */

import React, { useEffect, useRef } from "react";
import * as twgl from "twgl.js";
import Code from "../../src/components/code";

type ShaderScript = {
  id: string;
  source: string;
};

function createShaderScripts(container: HTMLElement, scripts: ShaderScript[]) {
  scripts.forEach((script) => {
    const scriptElement = document.createElement("script");
    scriptElement.type = "x-shader";
    scriptElement.innerHTML = script.source;
    scriptElement.setAttribute("id", script.id);
    container.appendChild(scriptElement);
  });
}

function startRenderLoop(
  fps: number,
  render: FrameRequestCallback
): () => void {
  const deltaTime = fps <= 1e-6 ? 0.0 : 1.0 / fps;
  let lastTime = 0;
  let stopped = false;
  let currentRequest = 0;

  const renderStep = (time: number) => {
    if (stopped) {
      return;
    }
    if (time - lastTime >= deltaTime) {
      lastTime = time;
      render(time);
    }
    currentRequest = window.requestAnimationFrame(renderStep);
  };
  currentRequest = window.requestAnimationFrame(renderStep);

  return () => {
    stopped = true;
    window.cancelAnimationFrame(currentRequest);
  };
}

type Props = {
  fps?: number;
  width: number;
  height: number;
  vs: ShaderScript;
  fs: ShaderScript;
  arrays: twgl.Arrays;
  uniforms: twgl.Arrays;
};

const ShaderPreviewer = ({
  fps,
  width,
  height,
  vs,
  fs,
  arrays,
  uniforms,
}: Props) => {
  if (typeof window === `undefined`) {
    return <div />;
  }

  // console.log("props = ", { fps, width, height, vs, fs, arrays, uniforms });

  const canvasRef = useRef<HTMLCanvasElement>();
  const containerRef = useRef();

  useEffect(() => {
    /** @type {WebGLRenderingContext} */
    const gl = canvasRef.current.getContext("webgl");
    createShaderScripts(containerRef.current, [vs, fs]);

    const programInfo = twgl.createProgramInfo(gl, [vs.id, fs.id]);
    const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);

    const render = (time) => {
      twgl.resizeCanvasToDisplaySize(canvasRef.current);
      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

      const mergedUniforms = {
        time: 0.001 * time,
        resolution: [gl.canvas.width, gl.canvas.height],
        ...uniforms,
      };

      gl.useProgram(programInfo.program);
      twgl.setBuffersAndAttributes(gl, programInfo, bufferInfo);
      twgl.setUniforms(programInfo, mergedUniforms);
      twgl.drawBufferInfo(gl, bufferInfo);
    };

    const cancel = startRenderLoop(fps, render);

    return () => {
      // console.log("cancel rendering loop");
      cancel();
    };
  }, []);

  return (
    <div ref={containerRef}>
      <div style={{ textAlign: "center" }}>
        <canvas ref={canvasRef} width={width} height={height} />
      </div>
      <code>Arrays</code>
      <Code
        codeString={JSON.stringify(arrays)}
        language="json"
        className="json"
      />
      <code>Vertex Shader</code>
      <Code codeString={vs.source} language="glsl" className="glsl" />
      <code>Fragment Shader</code>
      <Code codeString={fs.source} language="glsl" className="glsl" />
    </div>
  );
};

export default ShaderPreviewer;
