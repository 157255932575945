import React from "react";
import ShaderPreviewer from "../../../components/shader-previewer";

type Props = {
  width: number;
  height: number;
};

const Example01 = ({ width = 400, height = 400 }: Props) => {
  const fps = 30;

  const vs = {
    id: "example01-vs",
    source: `
attribute vec4 position;

void main() {
  gl_Position = position;
}
`,
  };

  const fs = {
    id: "example01-fs",
    source: `
precision mediump float;

uniform vec2 resolution;
uniform float time;

void main() {
  vec2 uv = gl_FragCoord.xy / resolution;
  float color = 0.0;
  // lifted from glslsandbox.com
  color += sin(uv.x * cos(time/3.0) * 60.0) + cos(uv.y * cos(time/2.80) * 10.0);
  color += sin(uv.y * sin(time/2.0) * 40.0) + cos(uv.x * sin(time/1.70) * 40.0);
  color += sin(uv.x * sin(time/1.0) * 10.0) + sin(uv.y * sin(time/3.50) * 80.0);
  color *= sin(time/10.0) * 0.5;

  gl_FragColor = vec4(vec3(color * 0.5, sin(color + time/2.5) * 0.75, color), 1.0);
}
`,
  };

  const uniforms = {};

  const arrays = {
    position: [-1, -1, 0, 1, -1, 0, -1, 1, 0, -1, 1, 0, 1, -1, 0, 1, 1, 0],
  };

  return (
    <ShaderPreviewer
      fps={fps}
      width={width}
      height={height}
      vs={vs}
      fs={fs}
      arrays={arrays}
      uniforms={uniforms}
    />
  );
};

export default Example01;
